import {useMemo} from 'react';
import {useParams} from 'react-router';

import {useIsInLoadedReportContext} from '../../../pages/ReportPage/LoadedReportContext';
import {
  useRampFlagDefaultGorillaOutliers,
  useRampFlagGorillaOutliersAvailable,
} from '../../../util/rampFeatureFlags';
import {RunsLinePlotConfig} from '../../PanelRunsLinePlot/types';
import {getLinePlotSettingWithDefault} from './defaults';
import {getCascadingSetting} from './getCascadingSetting';
import {
  DerivedPointVisualizationOption,
  InheritedSettings,
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
  ShowMinMaxOnHover,
} from './types';

/**
 * Narrrow down the point visualization method options into the two used in the radio UX
 * 'bucketing-gorilla' and 'sampling'
 */
export const getDerivedPointVisualizationOption = (
  p: PointVisualizationOptions | undefined
): DerivedPointVisualizationOption => {
  if (
    p === POINT_VISUALIZATION_OPTIONS.BucketingGorilla ||
    p === POINT_VISUALIZATION_OPTIONS.ForceBucketing
  ) {
    return POINT_VISUALIZATION_OPTIONS.BucketingGorilla;
  }

  if (
    p === POINT_VISUALIZATION_OPTIONS.Sampling ||
    p === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  return getLinePlotSettingWithDefault(p, 'pointVisualizationMethod');
};

export const useCascadingPointAggregationMethod = (
  parentSettings: InheritedSettings | undefined,
  panelSettings?: RunsLinePlotConfig
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
  showMinMaxOnHover?: ShowMinMaxOnHover;
} => {
  const {entityName} = useParams<{entityName?: string}>();
  if (!entityName) {
    // This check should narrow the type of `entityName` from `undefined | string`
    // to `string` in subsequent code.
    throw new RangeError(`Current entity name unexpectedly empty!`);
  }

  const isInReport = useIsInLoadedReportContext();
  const hasRunOutliersAccess = useRampFlagGorillaOutliersAvailable();
  const isFullFidelityDefaultEligible =
    useRampFlagDefaultGorillaOutliers(entityName);

  return useMemo(
    () =>
      getCascadingPointAggregationMethod(
        {isInReport, hasRunOutliersAccess, isFullFidelityDefaultEligible},
        parentSettings,
        panelSettings
      ),
    [
      hasRunOutliersAccess,
      isFullFidelityDefaultEligible,
      isInReport,
      panelSettings,
      parentSettings,
    ]
  );
};

export const getCascadingPointAggregationMethod = (
  config: {
    isInReport: boolean;
    hasRunOutliersAccess: boolean;
    isFullFidelityDefaultEligible: boolean;
  },
  parentSettings: InheritedSettings | undefined,
  panelSettings?: RunsLinePlotConfig
) => {
  const pointVisualizationMethodSettings = getCascadingPointVisualizationMethod(
    config,
    parentSettings?.pointVisualizationMethod,
    panelSettings?.pointVisualizationMethod
  );

  const showMinMaxOnHover = getDerivedShowMinMaxOnHover(
    config,
    pointVisualizationMethodSettings,
    parentSettings?.showMinMaxOnHover,
    panelSettings?.showMinMaxOnHover
  );

  return {
    pointVisualizationMethod:
      pointVisualizationMethodSettings.pointVisualizationMethod,
    showMinMaxOnHover,
  };
};

export const getCascadingPointVisualizationMethod = (
  config: {
    isInReport: boolean;
    hasRunOutliersAccess: boolean;
    isFullFidelityDefaultEligible: boolean;
  },
  parentSetting: InheritedSettings['pointVisualizationMethod'],
  panelSetting?: RunsLinePlotConfig['pointVisualizationMethod']
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
  isForced: boolean;
} => {
  // bucketing is not available in reports
  // users w/out access to run outliers will always be in sampling mode
  if (config.isInReport || !config.hasRunOutliersAccess) {
    return {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
      isForced: false,
    };
  }

  // if user specified a value, this takes precedence
  if (panelSetting != null) {
    return {
      pointVisualizationMethod:
        getDerivedPointVisualizationOption(panelSetting),
      isForced: false,
    };
  }

  /**
   * users who are enrolled to default to bucketing mode will be defaulted IF
   * they have yet to select a preferred point visualization method for the workspace
   */
  if (
    config.isFullFidelityDefaultEligible &&
    (parentSetting === POINT_VISUALIZATION_OPTIONS.SamplingByDefault ||
      parentSetting == null)
  ) {
    return {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      isForced: true,
    };
  }

  return {
    pointVisualizationMethod: getDerivedPointVisualizationOption(parentSetting),
    isForced: false,
  };
};

const getDerivedShowMinMaxOnHover = (
  config: {
    isInReport: boolean;
    hasRunOutliersAccess: boolean;
    isFullFidelityDefaultEligible: boolean;
  },
  pointVisualizationMethodSettings: ReturnType<
    typeof getCascadingPointVisualizationMethod
  >,
  parentSetting: InheritedSettings['showMinMaxOnHover'],
  panelSetting?: RunsLinePlotConfig['showMinMaxOnHover']
): ShowMinMaxOnHover | undefined => {
  /**
   * When we're forcing users into bucketing, we want to turn off the min/max on hover setting to make it a transition with less visual noise
   * https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1725476336968349?thread_ts=1725473178.406139&cid=C062UKHTAHG
   */
  if (
    panelSetting == null &&
    config.isFullFidelityDefaultEligible &&
    pointVisualizationMethodSettings.isForced
  ) {
    return ShowMinMaxOnHover.NEVER;
  }

  return getDerivedSafeMinMaxOnHoverValue(
    getCascadingSetting([parentSetting, panelSetting])
  );
};

const minMaxValueMapper: Record<string, ShowMinMaxOnHover> = {
  [ShowMinMaxOnHover.NEVER]: ShowMinMaxOnHover.NEVER,
  [ShowMinMaxOnHover.ALWAYS]: ShowMinMaxOnHover.ALWAYS,
  [ShowMinMaxOnHover.ON_HOVER]: ShowMinMaxOnHover.ON_HOVER,
  true: ShowMinMaxOnHover.ON_HOVER,
  false: ShowMinMaxOnHover.ALWAYS,
};
export const getDerivedSafeMinMaxOnHoverValue = (
  value: boolean | undefined | ShowMinMaxOnHover
): ShowMinMaxOnHover | undefined => {
  return value == null ? undefined : minMaxValueMapper[value.toString()];
};
