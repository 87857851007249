import {useMemo} from 'react';

import {usePart} from '../../../state/views/hooks';
import * as PanelTypes from '../../../state/views/panel/types';
import {useDeepEqualValue} from '../../../util/hooks';
import {getHasSystemMetrics} from '../../PanelRunsLinePlot/common';
import {RunsLinePlotConfig} from '../../PanelRunsLinePlot/types';
import {DEFAULT_X_AXIS_SETTINGS} from './defaults';
import {useCascadingPointAggregationMethod} from './getCascadingPointAggregation';
import {getCascadingSetting} from './getCascadingSetting';
import {getCascadingShowLegend} from './getCascadingShowLegend';
import {useSectionSettingsContext} from './SectionSettingsContextProvider';
import {InheritedSettings, XAxisValues} from './types';
import {areXAxisSettingsActive, isSettingActive} from './utils';
import {useWorkspaceSettingsContext} from './WorkspaceSettingsContextProvider';

/**
 * This hook determines which setting should be used in the panel.
 * This should only be used on a workspace page. Reports has its
 * own cascading logic because we don't have access to the context
 * providers and it's still on the old settings structure.
 *
 * Hierarchy: sectionSettings >> workspaceSettings
 *
 * This hook returns either the section or workspace cascading setting.
 */
export const useCascadingWorkspaceSettings = (): InheritedSettings => {
  const workspaceSettings = useWorkspaceSettingsContext();
  const workspaceLinePlotSettings = workspaceSettings.linePlot;
  const sectionSettings = useSectionSettingsContext();
  const sectionLinePlotSettings = sectionSettings.linePlot;

  // this should map `supressLegends` to `showLegend` because that's the field used in panel configs
  const cascadingSettings = useMemo(
    () => ({
      ...getXAxisSettings(workspaceLinePlotSettings, sectionLinePlotSettings),

      // Below do not have section setting, so inherit workspace value
      ignoreOutliers: workspaceLinePlotSettings?.ignoreOutliers,
      colorRunNames: workspaceLinePlotSettings?.colorRunNames,
      highlightedCompanionRunOnly:
        workspaceLinePlotSettings?.highlightedCompanionRunOnly,
      showMinMaxOnHover: workspaceLinePlotSettings?.showMinMaxOnHover,
      pointVisualizationMethod:
        workspaceLinePlotSettings?.pointVisualizationMethod,
      tooltipNumberOfRuns: workspaceLinePlotSettings?.tooltipNumberOfRuns,

      // These settings are not named the same at workspace and panel level, so map to the field used at panel level for consistency
      showLegend: getCascadingShowLegend({
        suppressLegends: workspaceLinePlotSettings?.suppressLegends,
      }),
      limit: workspaceLinePlotSettings?.maxRuns,
    }),
    [sectionLinePlotSettings, workspaceLinePlotSettings]
  );

  return cascadingSettings;
};

/**
 * This hook determine if the parent cascading settings or panel setting
 * should be used. It only gets used in PanelCompRedux.
 *
 * Hierarchy: panelConfig >> (sectionSettings >> workspaceSettings)
 *
 * @param inheritedSettings either the workspace or section setting
 */
export const usePanelWithInheritedSettings = (
  panelRef: PanelTypes.Ref,
  inheritedSettings: InheritedSettings | undefined
) => {
  const panel = usePart(panelRef);
  const isRunsLinePlot = panel.viewType === 'Run History Line Plot';

  const cascadingPointAggregationSettings = useCascadingPointAggregationMethod(
    inheritedSettings,
    isRunsLinePlot ? panel.config : undefined
  );

  const modifiedPanel = useMemo(() => {
    if (inheritedSettings == null) {
      return panel;
    }

    // We need to pipe through useRunsTableGroupingInPanels so we can show
    // individual runs in the run comparer
    if (panel.viewType === 'Run Comparer') {
      return {
        ...panel,
        config: {
          ...panel.config,
          useRunsTableGroupingInPanels:
            inheritedSettings?.useRunsTableGroupingInPanels,
        },
      };
    }

    if (isRunsLinePlot) {
      return {
        ...panel,
        config: getLinePlotWithInheritedSettings(
          cascadingPointAggregationSettings,
          panel.config,
          inheritedSettings
        ),
      };
    }

    return panel;
  }, [
    inheritedSettings,
    panel,
    isRunsLinePlot,
    cascadingPointAggregationSettings,
  ]);

  const deepEqualPanel = useDeepEqualValue(modifiedPanel);
  return deepEqualPanel;
};

export const getLinePlotWithInheritedSettings = (
  cascadingPointAggregationSettings: ReturnType<
    typeof useCascadingPointAggregationMethod
  >,
  panelConfig: RunsLinePlotConfig,
  inheritedSettings: InheritedSettings | undefined
) => {
  return {
    // Order matters here
    ...inheritedSettings, // workspace or section settings
    ...panelConfig, // settings that are not migrated yet - goal is to move them into cascading function
    // migrated settings
    ...getCascadingPanelSettings(
      cascadingPointAggregationSettings,
      inheritedSettings,
      undefined,
      panelConfig
    ),
  };
};

/**
 * We do further transformations on the x-axis depending on the selected x-axis
 * value and if panel is a system metric.
 */
export const getCascadingPanelSettings = (
  cascadingPointAggregationSettings: ReturnType<
    typeof useCascadingPointAggregationMethod
  >,
  parentSettings: InheritedSettings | undefined,
  childSettings?: InheritedSettings,
  panelSettings?: RunsLinePlotConfig
) => {
  const {pointVisualizationMethod, showMinMaxOnHover} =
    cascadingPointAggregationSettings;

  return {
    ...getXAxisSettings(parentSettings, childSettings, panelSettings),

    // section setting doesn't exist, so inherit workspace value
    ignoreOutliers: getCascadingSetting([
      parentSettings?.ignoreOutliers,
      panelSettings?.ignoreOutliers,
    ]),
    colorRunNames: parentSettings?.colorRunNames,
    highlightedCompanionRunOnly: parentSettings?.highlightedCompanionRunOnly,

    // These settings are under development and cascading logic is determined at a higher level for now, so just stick these values in
    pointVisualizationMethod,
    showMinMaxOnHover,
    tooltipNumberOfRuns: parentSettings?.tooltipNumberOfRuns,

    // These settings are not named the same at workspace and panel level, so map to the field used at panel level for consistency
    showLegend: getCascadingSetting([
      parentSettings?.showLegend,
      panelSettings?.showLegend,
    ]),
    limit: getCascadingSetting([parentSettings?.limit, panelSettings?.limit]),
  };
};

const getXAxisSettings = (
  parentSettings: InheritedSettings | undefined,
  childSettings?: InheritedSettings,
  panelSettings?: RunsLinePlotConfig
): {
  xAxis: XAxisValues | string;
  xAxisMin?: number;
  xAxisMax?: number;
} => {
  const xAxis = getXAxis(parentSettings, childSettings, panelSettings);

  return {
    xAxis,
    xAxisMin: getXAxisRangeValue(
      'xAxisMin',
      xAxis,
      parentSettings,
      childSettings,
      panelSettings
    ),
    xAxisMax: getXAxisRangeValue(
      'xAxisMax',
      xAxis,
      parentSettings,
      childSettings,
      panelSettings
    ),
  };
};

const getXAxis = (
  parentSettings: InheritedSettings | undefined,
  childSettings?: InheritedSettings,
  panelSettings?: RunsLinePlotConfig
) => {
  const isSystemMetricPanel =
    panelSettings != null && getHasSystemMetrics(panelSettings);
  const xAxis = getCascadingSetting(
    [parentSettings?.xAxis, childSettings?.xAxis],
    DEFAULT_X_AXIS_SETTINGS.xAxis
  );

  if (panelSettings?.xAxis != null) {
    return panelSettings?.xAxis;
  }

  if (isSystemMetricPanel) {
    // the panel doesn't have its own xAxis, but it can't use the global
    // xAxis because it isn't compatible with system metrics -- fall back
    // to '_runtime'
    if (['_runtime', '_timestamp'].indexOf(xAxis) === -1) {
      return '_runtime';
    }
    return xAxis;
  }

  if (
    panelSettings?.startingXAxis != null &&
    !areXAxisSettingsActive(childSettings ?? parentSettings)
  ) {
    // if the global xAxis is not active use default
    return panelSettings?.startingXAxis;
  }

  if (areXAxisSettingsActive(childSettings)) {
    return childSettings?.xAxis;
  }

  return xAxis;
};

const getXAxisRangeValue = (
  field: 'xAxisMin' | 'xAxisMax',
  globalXAxis: XAxisValues | string | undefined,
  parentSettings: InheritedSettings | undefined,
  childSettings?: InheritedSettings,
  panelSettings?: RunsLinePlotConfig
) => {
  if (panelSettings != null && panelSettings[field] != null) {
    return panelSettings[field];
  }

  // Note: we treat default setting as if user hasn't modified setting yet
  if (
    panelSettings == null ||
    !isSettingActive(panelSettings?.xAxis, DEFAULT_X_AXIS_SETTINGS.xAxis) ||
    globalXAxis === panelSettings?.xAxis
  ) {
    // we can only use the global value if the global xAxis matches the
    // one we're actually using for this panel
    return getCascadingSetting(
      [parentSettings, childSettings].map(item =>
        item != null && field in item ? item[field] : undefined
      )
    );
  }

  return undefined;
};
