import * as Normalize from './normalize';
import {ViewReducerState} from './reducerSupport';

export function handleUpdateViewSpec(
  state: ViewReducerState,
  viewId: string,
  spec: any,
  opts: {wholeIsDefinitelyNew: boolean}
) {
  // Store normalized result
  const stateView = state.views[viewId];

  const partRef = Normalize.addObj(
    state.parts,
    stateView.type,
    viewId,
    spec,
    opts
  );

  // Store raw result
  if (stateView == null) {
    throw new Error('invalid view state');
  }
  stateView.partRef = partRef;

  const date = new Date();
  stateView.updatedAt = date.toISOString();
}
