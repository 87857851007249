import {OrganizedSettings} from '../../PanelBank/types';
import {RunsLinePlotConfig} from '../../PanelRunsLinePlot/types';
import {getLinePlotSettingWithDefault} from './defaults';
import {getCascadingMaxRuns} from './getCascadingMaxRuns';
import {
  getDerivedPointVisualizationOption,
  getDerivedSafeMinMaxOnHoverValue,
} from './getCascadingPointAggregation';
import {getCascadingShowLegend} from './getCascadingShowLegend';
import {LinePlotSettings} from './types';

/**
 * This is used by both the workspace and section level context providers.
 * When setting value is null, we want to preserve it because this indicates
 * that a user hasn't modified the setting yet.
 */
export const getLinePlotSettings = (organizedSettings?: OrganizedSettings) => {
  const linePlotSettings = organizedSettings?.linePlot;

  return {
    // These settings are at workspace, section, and panel level.
    smoothingType: linePlotSettings?.smoothingType,
    smoothingWeight: linePlotSettings?.smoothingWeight,
    xAxis: linePlotSettings?.xAxis,
    xAxisMax: linePlotSettings?.xAxisMax,
    xAxisMin: linePlotSettings?.xAxisMin,

    // These settings are both at workspace and panel level.
    ignoreOutliers: linePlotSettings?.ignoreOutliers,
    maxRuns: linePlotSettings?.maxRuns,

    // These settings are only at workspace settings at the moment.
    colorRunNames: linePlotSettings?.colorRunNames,
    displayFullRunName: linePlotSettings?.displayFullRunName,
    highlightedCompanionRunOnly: linePlotSettings?.highlightedCompanionRunOnly,
    suppressLegends: linePlotSettings?.suppressLegends,
    tooltipNumberOfRuns: linePlotSettings?.tooltipNumberOfRuns,
  };
};

/**
 * @param settingsType indicates what level the settings are
 *
 * This is used at the panel/most specific level. It will take the current value or the default value.
 * We want to resolve at the panel level, so that we can easily add or remove settings
 * at both the workspace and section levels.
 */
export const getLinePlotSettingsWithDefaults = (
  settingsObj: LinePlotSettings | RunsLinePlotConfig | undefined
) => {
  const showLegend = getCascadingShowLegend({
    suppressLegends:
      settingsObj != null && 'suppressLegends' in settingsObj
        ? settingsObj?.suppressLegends
        : undefined,
    showLegend: settingsObj?.showLegend,
  });

  const pointVisualizationMethod = getDerivedPointVisualizationOption(
    settingsObj?.pointVisualizationMethod
  );

  const limit = getCascadingMaxRuns({
    pointVisualizationMethod,
    maxRuns:
      settingsObj != null && 'maxRuns' in settingsObj
        ? settingsObj?.maxRuns
        : undefined,
    limit:
      settingsObj != null && 'limit' in settingsObj
        ? settingsObj?.limit
        : undefined,
  });

  return {
    colorRunNames: getLinePlotSettingWithDefault(
      settingsObj?.colorRunNames,
      'colorRunNames'
    ),
    highlightedCompanionRunOnly: getLinePlotSettingWithDefault(
      settingsObj?.highlightedCompanionRunOnly,
      'highlightedCompanionRunOnly'
    ),
    ignoreOutliers: getLinePlotSettingWithDefault(
      settingsObj?.ignoreOutliers,
      'ignoreOutliers'
    ),
    limit: getLinePlotSettingWithDefault(limit, 'limit'),
    pointVisualizationMethod,
    showLegend: getLinePlotSettingWithDefault(showLegend, 'showLegend'),
    showMinMaxOnHover: getLinePlotSettingWithDefault(
      getDerivedSafeMinMaxOnHoverValue(settingsObj?.showMinMaxOnHover),
      'showMinMaxOnHover'
    ),
    tooltipNumberOfRuns: getLinePlotSettingWithDefault(
      settingsObj?.tooltipNumberOfRuns,
      'tooltipNumberOfRuns'
    ),
    xAxis: getLinePlotSettingWithDefault(settingsObj?.xAxis, 'xAxis'),
    xAxisMin: settingsObj?.xAxisMin,
    xAxisMax: settingsObj?.xAxisMax,
  };
};
